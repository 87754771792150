<!--
 * @Date: 2023-11-20 15:47:56
 * @LastEditTime: 2024-01-17 17:09:31
 * @Description: file content
-->
<template>
  <div class="listCode">
    <Viewheader class="full-width" :isHome="false" />
    <div class="listCodeMain">
      <div class="codeNav">
        <leftNav />
      </div>
      <div class="codeList">
        <div class="codeName">code管理 > code创建 > code确认</div>
        <div class="yPcklQR">
          <div class="yPcklQRDetail">
            <div class="detailTitle">业务名称：{{ bizName }}</div>
            <div class="detailTitle">bizcode：{{ bizCode }}</div>
            <div class="detailTitle">调用有效期：{{ validityDateValue }}</div>
            <!-- <div class="detailTitle">工程地址：xxxxxxxxxxx</div>  -->
            <div class="detailTitle">工程接口：{{ projectInterface }}</div>
            <div class="yPcklQRButton">
              <el-button class="detailBack" @click="backForm()">返回</el-button>
              <el-button type="primary" @click="listForm()">确认</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Viewheader from '../../components/viewheader/viewheader.vue';
import Footering from '../../components/foot/viewfooter.vue';
import leftNav from '../../views/code/leftNav.vue';
import '../../assets/css/code.css';
export default {
  components: {
    Viewheader,
    Footering,
    leftNav,
  },
  data() {
    return {
      title: '关于我们',
      contents: [
        'AIShipGo是一个专注于跨境电商人工智能技术与解决方案的产品技术平台。凭借着海量的电商数据、国际领先的算法和工程能力，以“让跨境电商的生意更简单、更高效”为目标，致力于为跨境卖家提供全面的人工智能技术支持，解决社媒引流、站内营销、风控安全、直播带货等业务问题。',
        '团队由一群经验丰富的AI专家和跨境电商从业者组成，深入了解跨境电商行业的挑战和机遇，结合先进的NLP、CV、多模态、ML等AIGC技术，为卖家提供全方位的解决方案。产品均已在真实跨境业务中应用，后续将持续开放更多能力。提供网页、SDK、私有化部署等对接方式，满足不同的应用需求，请关注我们，了解更多的最新动态。',
      ],
      bizName: '',
      bizCode: '',
      validityDateValue: '',
      projectInterface: '',
    };
  },
  mounted() {
    this.bizName = this.$route.query.bizName;
    this.bizCode = this.$route.query.bizCode;
    this.validityDateValue = this.$route.query.validityDateValue;
    this.projectInterface = this.$route.query.projectInterface;
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    listForm() {
      this.$router.push({
        path: '/listCode',
      });
    },
    // 返回
    backForm() {
      this.$router.push({
        path: '/addCode',
      });
    },
    beforeRouteLeave(to, from, next) {
      to.meta.keepAlive = true;
      next(0);
    },
  },
};
</script>
